import React from 'react';

import {
  BooleanField,
  BooleanInput,
  Create,
  Edit,
  Filter,
  NumberField,
  ReferenceField,
  SaveButton,
  Show,
  ShowButton,
  SimpleForm,
  Tab,
  TabbedShowLayout,
  TextField,
  TextInput,
  Toolbar,
  useRecordContext,
} from 'react-admin';

import { AdminLocationDetailsFragment } from '../../graphql/types';
import useLocationCoachesQuery from '../../hooks/useLocationCoachesQuery';

import AutocompleteStudioInput from './components/AutocompleteStudioInput';
import InfiniteList from './components/InfiniteList';
import { ListCoaches } from './components/ListCoaches';
import { WriteOnlyDatagrid } from './components/WriteOnlyDatagrid';
import { WriteOnlyEditButton } from './components/WriteOnlyEditButton';

const LocationToolbar = () => (
  <Toolbar>
    <SaveButton />
  </Toolbar>
);

const LocationFilters: React.FC = (props) => (
  <Filter {...props}>
    <TextInput label="Name" source="name" alwaysOn />
  </Filter>
);

export const LocationList: React.FC = () => (
  <InfiniteList filters={<LocationFilters />}>
    <WriteOnlyDatagrid bulkActionButtons={false}>
      <TextField source="name" />
      <TextField source="stateCode" />
      <TextField source="mboLocationId" />
      <TextField source="studio.name" label="Studio" />
      <BooleanField source="isHeadquarters" label="HQ" />
      <NumberField source="coaches.count" label="Coaches" />
      <WriteOnlyEditButton />
      <ShowButton />
    </WriteOnlyDatagrid>
  </InfiniteList>
);

const LocationCoachTab: React.FC = () => {
  const record = useRecordContext<AdminLocationDetailsFragment>();
  const { coaches, loadMore, hasNextPage } = useLocationCoachesQuery(
    record?.id,
  );

  return (
    <ListCoaches
      coaches={coaches}
      loadMore={loadMore}
      hasNextPage={hasNextPage}
    />
  );
};

export const LocationShow: React.FC = () => (
  <Show>
    <TabbedShowLayout>
      <Tab label="Basic Info">
        <TextField source="id" />
        <TextField source="name" />
        <TextField source="stateCode" />
        <TextField source="mboLocationId" />
        <BooleanField source="isHeadquarters" label="HQ" />
        <ReferenceField source="studio.id" reference="Studio" label="Studio">
          <TextField source="name" />
        </ReferenceField>
      </Tab>
      <Tab label="Coaches">
        <LocationCoachTab />
      </Tab>
    </TabbedShowLayout>
  </Show>
);

export const LocationEdit: React.FC = () => (
  <Edit mutationMode="pessimistic" redirect={false}>
    <SimpleForm toolbar={<LocationToolbar />}>
      <TextInput source="id" InputProps={{ disabled: true }} />
      <TextInput source="name" />
      <TextInput source="stateCode" />
      <TextInput source="mboLocationId" />
      <BooleanInput source="isHeadquarters" />
      <AutocompleteStudioInput source="studio.id" label="Studio" />
    </SimpleForm>
  </Edit>
);

export const LocationCreate: React.FC = () => (
  <Create>
    <SimpleForm>
      <TextInput source="name" />
      <TextInput source="stateCode" />
      <TextInput source="mboLocationId" />
      <BooleanInput source="isHeadquarters" />
      <AutocompleteStudioInput source="studio.id" label="Studio" />
    </SimpleForm>
  </Create>
);
