import * as React from 'react';

import { DayOfWeek } from '@js-joda/core';
import { Warning } from '@mui/icons-material';
import { Box, Checkbox, FormLabel, Typography } from '@mui/material';
import { UserCheckResponse, useAuth } from '@teamexos/fit-shared';
import {
  ArrayField,
  BooleanField,
  DateField,
  DeleteWithConfirmButton,
  FunctionField,
  ImageField,
  ReferenceArrayField,
  ReferenceField,
  Show,
  ShowButton,
  SingleFieldList,
  Tab,
  TabbedShowLayout,
  TabbedShowLayoutTabs,
  TextField,
  TopToolbar,
  useRecordContext,
} from 'react-admin';

import {
  AdminUserDetailsFragment,
  CoachingStyle,
  useAdminCountryListQuery,
} from '../../../graphql/types';
import useCanWrite from '../../../hooks/useCanWrite';
import { SplitFlags, useFeatureFlags } from '../../../hooks/useFeatureFlags';
import AvailabilityViewerWrapper from '../components/AvailabilityViewer';
import ClipboardField from '../components/ClipboardField';
import ConnectionsList from '../components/ConnectionsList';
import { Divider } from '../components/Divider';
import { ListCoachClients } from '../components/ListCoachClients';
import ListCoachMembers from '../components/ListCoachMembers';
import { MultiTimeAgoField } from '../components/TimeAgoField';
import { WriteOnlyDatagrid } from '../components/WriteOnlyDatagrid';
import { WriteOnlyEditButton } from '../components/WriteOnlyEditButton';

import { MemberProfileTab } from './components/MemberProfileTab';
import { MemberSessionsTab } from './components/MemberSessionsTab';
import { UserAuditLogsTab } from './components/UserAuditLogsTab';
import { UserTabStyle, UserTitle, getCoachingStyleLabel } from './util';

const daysOfWeekMap = {
  [DayOfWeek.MONDAY.value()]: 'Monday',
  [DayOfWeek.TUESDAY.value()]: 'Tuesday',
  [DayOfWeek.WEDNESDAY.value()]: 'Wednesday',
  [DayOfWeek.THURSDAY.value()]: 'Thursday',
  [DayOfWeek.FRIDAY.value()]: 'Friday',
  [DayOfWeek.SATURDAY.value()]: 'Saturday',
  [DayOfWeek.SUNDAY.value()]: 'Sunday',
};

const UserShowActions: React.FC = () => {
  const canWrite = useCanWrite();
  const record = useRecordContext<AdminUserDetailsFragment>();
  const confirmTitle = `Delete ${record?.firstName} ${record?.lastName} (${record?.email})?`;
  const confirmContent = `This will delete all of ${record?.firstName}'s data and can not be undone.`;

  const confirmContentKeepIterable = `This will delete all of ${record?.firstName}'s data, but keep them on Iterable. This can not be undone.`;

  return (
    <TopToolbar>
      <WriteOnlyEditButton />
      {record && canWrite && (
        <>
          <DeleteWithConfirmButton
            confirmColor="warning"
            confirmTitle={confirmTitle}
            confirmContent={confirmContent}
          />
          <DeleteWithConfirmButton
            label="Delete, but keep on iterable"
            confirmColor="warning"
            confirmTitle={confirmTitle}
            confirmContent={confirmContentKeepIterable}
            mutationOptions={{
              meta: {
                forgetIterable: false,
              },
            }}
          />
        </>
      )}
    </TopToolbar>
  );
};

const UserShowComponent: React.FC = () => {
  const { flags } = useFeatureFlags();
  const enableCoachClientConfig = flags[SplitFlags.COACH_CLIENT_CONFIG];
  const enableCoachClientAvailability =
    flags[SplitFlags.COACH_CLIENT_AVAILABILITY];

  const record = useRecordContext<AdminUserDetailsFragment>();
  const { userCheck } = useAuth();

  const [userCheckError, setUserCheckError] = React.useState<string>();
  const [userAuthInfo, setUserAuthInfo] = React.useState<UserCheckResponse>();

  React.useEffect(() => {
    async function getUserCheck(email: string) {
      try {
        const info = await userCheck(email, true);
        setUserAuthInfo(info);
      } catch (e) {
        setUserCheckError(e.message);
      }
    }

    if (record?.email) {
      getUserCheck(record.email);
    }
  }, [record?.email, userCheck]);

  const { data: countryList } = useAdminCountryListQuery();
  const countryName =
    countryList &&
    record &&
    countryList.data.find((country) => country.alpha2 === record.countryCode)
      ?.name;

  if (!record) {
    return null;
  }

  const bufferIsEnabled =
    record.coachData?.calendarBuffer &&
    (record.coachData.calendarBuffer.beforeInMinutes !== 0 ||
      record.coachData.calendarBuffer.afterInMinutes !== 0);

  return (
    <TabbedShowLayout
      tabs={
        <TabbedShowLayoutTabs
          sx={{ '& .MuiTabs-flexContainer': { flexWrap: 'wrap' } }}
        />
      }
    >
      <Tab label="Basics" sx={UserTabStyle}>
        <ClipboardField source="id" fontSize={14} />
        <TextField source="firstName" data-source="firstName" />
        <TextField source="lastName" data-source="lastName" />
        <ClipboardField source="email" fontSize={14} data-source="email" />
        <TextField source="birthday" />
        <TextField source="pronouns" />
        <TextField
          source="countryName"
          record={{ countryName }}
          label="Country"
        />
        <BooleanField source="active" />
        <BooleanField source="isAdmin" />
        <BooleanField source="isReadOnlyAdmin" />
        <BooleanField source="isCoach" />
        <BooleanField source="isTestUser" />
        <DateField source="createdAt" showTime />
        <ImageField source="avatar.url" label="Avatar" />
      </Tab>
      <Tab label="Profile" sx={UserTabStyle}>
        <MemberProfileTab />
      </Tab>
      <Tab label="Sessions" sx={UserTabStyle}>
        <MemberSessionsTab />
      </Tab>
      <Tab label="Fit" sx={UserTabStyle}>
        <MultiTimeAgoField
          label="Last Fit Activity"
          source="lastActivity"
          subfields={['heart', 'heartWeb']}
        />
        <ReferenceField
          source="fitClient.id"
          reference="Client"
          label="Client"
          link="show"
        >
          <FunctionField
            render={(rec?: Record<string, any>) => rec?.name ?? ''}
          />
        </ReferenceField>
        <ReferenceField source="fitCoach.id" reference="User" label="Fit Coach">
          <FunctionField
            render={(rec: Record<string, any> | undefined) =>
              `${rec?.firstName} ${rec?.lastName}`
            }
          />
        </ReferenceField>
        <BooleanField
          source="hasHighPerformanceCoaching"
          label="User has High-Performance Coaching"
        />
        <ReferenceField
          source="fitClient.id"
          reference="Client"
          label="Client uses High-Performance Coaching"
          link={false}
        >
          <BooleanField source="usesHighPerformanceCoaching" />
        </ReferenceField>
      </Tab>
      <Tab label="Family" sx={UserTabStyle}>
        <ReferenceField
          source="eligibleEmailAddress.invitingUser.id"
          reference="User"
          label="Family Manager"
          emptyText="Is the manager"
          link="show"
        />
        <ArrayField source="familyInvitations" label="Family Members">
          <WriteOnlyDatagrid bulkActionButtons={false}>
            <TextField source="email" />
            <TextField source="invitingRelationship" />
            <BooleanField source="isArchived" />
            <DateField source="createdAt" showTime />
            <ReferenceField
              source="user.id"
              reference="User"
              label="User"
              link="show"
              emptyText="Not registered"
            />
            <ShowButton resource="EligibleEmailAddress" />
          </WriteOnlyDatagrid>
        </ArrayField>
      </Tab>
      {record.isCoach && (
        <Tab label="Coach Data" sx={UserTabStyle}>
          <TextField
            source="coachData.bio"
            label="Bio"
            data-source="coachData.bio"
          />
          <TextField source="coachData.type" label="Type" />
          <FunctionField
            label="Coaching Style"
            render={(rec: Record<string, any> | undefined) =>
              getCoachingStyleLabel(rec?.coachData?.coachingStyle)
            }
          />
          <ImageField source="coachData.headshot.url" label="Headshot Photo" />
          <ImageField source="coachData.bodyPhoto.url" label="Body Photo" />
          <FunctionField
            label="Passions"
            render={(rec: Record<string, any> | undefined) => {
              const passions = rec?.coachData?.passions ?? [];

              return passions.join(', ');
            }}
          />

          {enableCoachClientConfig ? (
            <ListCoachClients />
          ) : (
            <ReferenceArrayField
              source="coachData.clients.ids"
              reference="Client"
              label="Clients"
            >
              <WriteOnlyDatagrid>
                <TextField source="name" />
                <ShowButton />
              </WriteOnlyDatagrid>
            </ReferenceArrayField>
          )}

          <ReferenceArrayField
            source="coachData.locations.ids"
            reference="CoachLocation"
            label="Locations"
          >
            <WriteOnlyDatagrid>
              <TextField source="name" />
              <ShowButton />
            </WriteOnlyDatagrid>
            <Divider />
          </ReferenceArrayField>
          <Typography variant="h5" fontSize={20}>
            Coach Matching Options
          </Typography>
          {!enableCoachClientAvailability && (
            <BooleanField
              source="coachData.availableForMemberAssignment"
              label="Available for Member Assignment"
            />
          )}

          <Typography variant="h6" fontSize={16}>
            International Coach Matching
          </Typography>
          <FunctionField
            label="Supported Countries"
            render={(userRecord: Record<string, any>) =>
              (userRecord.coachData?.supportedCountries.length || 0) > 0
                ? `${userRecord.coachData?.supportedCountries
                    .map(
                      (supportedCountries: { name?: string }) =>
                        supportedCountries.name,
                    )
                    .join(', ')}`
                : 'No supported countries'
            }
          />
          <Typography variant="h6" fontSize={16}>
            High Performance Coaching - Goal Matching Support
          </Typography>
          <FunctionField
            label="Supported Goals"
            render={(userRecord: Record<string, any>) =>
              (userRecord.coachData?.supportedGoals.length || 0) > 0
                ? `${userRecord.coachData?.supportedGoals
                    .map(
                      (supportedGoals: { description?: string }) =>
                        supportedGoals.description,
                    )
                    .join(', ')}`
                : 'No supported goals'
            }
          />
        </Tab>
      )}
      {record.isCoach && (
        <Tab label="Coach Members" sx={UserTabStyle}>
          <ListCoachMembers coach={record} />
        </Tab>
      )}
      <Tab label="Chats" sx={UserTabStyle}>
        <ReferenceArrayField
          source="chatRooms.ids"
          reference="ChatRoom"
          label="Coach Chats"
        >
          <WriteOnlyDatagrid isRowSelectable={() => false}>
            <ClipboardField source="id" />
            <TextField source="name" />
            <TextField source="lastMessageReceivedAt" />
            <TextField source="messages.count" label="Messages" />
            <TextField source="users.count" label="User Count" />
            <ReferenceArrayField
              source="users.ids"
              reference="User"
              label="First 3 Users"
              perPage={3}
              sortable={false}
            >
              <SingleFieldList linkType="show" />
            </ReferenceArrayField>
            <BooleanField source="isArchived" label="Archived" />
            <ShowButton />
          </WriteOnlyDatagrid>
        </ReferenceArrayField>
      </Tab>
      <Tab label="Devices" sx={UserTabStyle}>
        <ArrayField source="devices" label="Devices">
          <WriteOnlyDatagrid>
            <BooleanField source="enabled" />
            <TextField source="name" />
            <TextField source="app" />
            <DateField source="createdAt" showTime />
            <DateField source="updatedAt" showTime />
          </WriteOnlyDatagrid>
        </ArrayField>
      </Tab>
      <Tab label="Auth History" sx={UserTabStyle}>
        <Typography variant="h6" fontSize={14}>
          Note: This only includes data for username/password users, not SSO
          users
        </Typography>
        <ArrayField source="authHistory.nodes" label="Auth History">
          <WriteOnlyDatagrid>
            <DateField source="eventDate" showTime />
            <TextField source="type" />
            <TextField source="status" />
            <TextField source="challengeResponses" />
          </WriteOnlyDatagrid>
        </ArrayField>
      </Tab>
      {record.isCoach && (
        <Tab label="Availability" sx={UserTabStyle}>
          <Typography variant="h6">Calendar</Typography>
          <span>
            This availability viewer shows the coach&apos;s current availability
            based on their configured availability rules and connected
            calendars.
          </span>
          {record.coachData?.coachingStyle === CoachingStyle.Hybrid &&
            record.coachData?.inOfficeDays?.length && (
              <p>
                Coach has set in-office days:{' '}
                {record.coachData.inOfficeDays
                  .map((day) => daysOfWeekMap[day])
                  .join(', ')}
                <br />
                These days are the only ones available for in-person sessions,
                otherwise any times shown below are available for virtual
                sessions.
              </p>
            )}
          {!record.coachData?.cronofyElementToken ? (
            <p>Coach has not connected to Cronofy</p>
          ) : (
            <AvailabilityViewerWrapper
              key={record.coachData?.cronofyElementToken}
              elementToken={record.coachData?.cronofyElementToken ?? ''}
              coachSub={record.coachData?.cronofySub ?? ''}
              availableDays={record.coachData?.inOfficeDays ?? []}
              calendarBuffer={record.coachData?.calendarBuffer}
              coachTimezone={record.coachData?.cronofyTimezone}
              dailySessionLimit={record.coachData?.dailySessionLimit}
            />
          )}
        </Tab>
      )}
      {record.isCoach && (
        <Tab label="Preferences" sx={UserTabStyle}>
          <Box>
            <Typography variant="h6">Timezone</Typography>
            <Typography>Timezone for calendar availability.</Typography>
            <Typography fontWeight="bold">
              {record.coachData?.cronofyTimezone}
            </Typography>
          </Box>
          <hr />
          <Box>
            <Typography variant="h6">Daily session limit</Typography>
            <Typography>
              Coaches can set a max number of coaching sessions per day, based
              on their current timezone setting.
            </Typography>
            <Typography fontWeight="bold">
              Current limit:{' '}
              {record.coachData?.dailySessionLimit ?? 'No limit set'}
            </Typography>
          </Box>
          <hr />
          <Box>
            <Typography variant="h6">Meeting Buffer</Typography>
            <Typography>
              Enabling this buffer adds 30 minutes after each session to
              prevents members from scheduling back-to-back sessions.
            </Typography>
            <Typography>
              Coaches can update this setting in Coach Hub.
            </Typography>
            <Checkbox checked={bufferIsEnabled} disabled />
            30 minute meeting buffer
          </Box>
        </Tab>
      )}
      {record.isCoach && (
        <Tab label="Connections" sx={UserTabStyle}>
          <Box>
            <Typography variant="h4">Platform Connections</Typography>
            <ConnectionsList coach={record} />
          </Box>
        </Tab>
      )}
      <Tab label="User Check" sx={UserTabStyle}>
        {userCheckError && (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '8px',
            }}
          >
            <Warning color="error" />
            <FormLabel>An unknown error occurred during user check.</FormLabel>
          </Box>
        )}
        <TextField
          label="Identity Connection (Auth0 Connection)"
          record={userAuthInfo}
          source="identity.connection"
        />
        <TextField
          label="Identity Provider"
          record={userAuthInfo}
          source="identity.provider"
        />
        <BooleanField
          label="Active (Member is active on shared-platform)"
          record={userAuthInfo}
          source="active"
        />
        <BooleanField
          label="Registered (User is registered on shared-platform and has a cognito user)"
          record={userAuthInfo}
          source="registered"
        />
        <BooleanField
          label="Email Registered (Email is registered on cognito)"
          record={userAuthInfo}
          source="emailRegistered"
        />
        <BooleanField
          label="Is Email Verified (Email is verified on cognito - SSO is always verified)"
          record={userAuthInfo}
          source="isEmailVerified"
        />
        <BooleanField
          label="Registration Enabled (Has a valid client to register with)"
          record={userAuthInfo}
          source="registrationEnabled"
        />
        <BooleanField
          label="Is SSO (Is Using SSO login)"
          record={userAuthInfo}
          source="isSSO"
        />
        <BooleanField
          label="Reset Required (Member must reset their password before they can sign in)"
          record={userAuthInfo}
          source="resetRequired"
        />
        <BooleanField
          label="Has Cognito User (User is created on cognito)"
          record={userAuthInfo}
          source="hasCognitoUser"
        />
      </Tab>
      <Tab label="Audit Logs" sx={UserTabStyle}>
        <UserAuditLogsTab />
      </Tab>
    </TabbedShowLayout>
  );
};

export const UserShow = () => (
  <Show title={<UserTitle />} actions={<UserShowActions />}>
    <UserShowComponent />
  </Show>
);
